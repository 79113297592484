<template>
  <div id="quotation-list">
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-row>
              <b-col xl="4" sm="12" v-if="is_admin">
                <b-form-group label="Inmobiliarias" label-for="inmobiliarias">
                  <v-select
                    v-model="filtersForm.real_state_agency"
                    label="name"
                    placeholder="Selecciona una inmobiliaria"
                    :options="realStateAgencies"
                    :reduce="realStateAgencies => realStateAgencies.id">
                    <span slot="no-options">
                      No se encontraron opciones
                    </span>
                  </v-select>
                </b-form-group> 
              </b-col>   

              <b-col xl="4" sm="12">
                <b-form-group label="Proyectos" label-for="projects">
                  <v-select
                    v-model="filtersForm.project"
                    label="name"
                    placeholder="Selecciona un Proyecto"
                    :options="filteredProjects"
                    :reduce="filteredProjects => filteredProjects.id"
                    :disabled="is_admin && (filtersForm.real_state_agency == '' || filtersForm.real_state_agency == null)"
                    >
                    <span slot="no-options">
                      No se encontraron opciones
                    </span>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col xl="4" sm="12">
                <b-form-group label="Fecha de Inicio" label-for="date_ini">
                  <b-form-datepicker
                    locale="es"
                    id="date_ini"
                    :max="filtersForm.end"
                    placeholder="Selecciona una fecha de inicio"
                    v-model="filtersForm.start"/>
                </b-form-group>
              </b-col>
              <b-col xl="4" sm="12">
                <b-form-group label="Fecha de Termino" label-for="date_end">
                  <b-form-datepicker
                    locale="es"
                    id="date_end"
                    :min="filtersForm.start"
                    placeholder="Selecciona una fecha de termino"
                    v-model="filtersForm.end"/>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-2">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-overlay
              :show="overlayList"
              opacity="1.0"
              spinner-small
              spinner-type="grow"
              spinner-variant="primary"
              @hidden="_onHidden()">
              <div class="d-flex align-items-end justify-content-end">
                <b-overlay
                  :show="overlayForm"
                  opacity="0.5"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                  @hidden="_onHidden()" >
                  <b-button class="mr-1"
                    ref="button"
                    variant="primary"
                    :disabled="overlayForm"
                    @click.prevent="_exportQuotations()" v-if="permissions_visible.download_quotations">
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"/>
                    <span class="align-middle">
                      Descargar Cotizaciones
                    </span>
                  </b-button>
                </b-overlay>
              </div>
              <div class="mt-2">
                <b-table
                  hover
                  striped
                  show-empty
                  responsive="xl"
                  ref="refQuotationsListTable"
                  class="position-relative mb-1"
                  :items="quotations.data"
                  :fields="tableColumns"
                  primary-key="id"
                  empty-text="Datos no Disponibles">
                  <template #cell(created_at)="data">
                    {{ data.item.created_at| moment("DD-MM-YYYY") }}
                  </template>
                  <template #cell(propertyquotation[0].property.project.name)="data">
                    {{ data.item.propertyquotation[0] != null ? data.item.propertyquotation[0].property.project.name : '-' }}
                  </template>
                  <template #cell(propertyquotation[0].property.identifier)="data">
                    {{ data.item.propertyquotation[0]  != null ? data.item.propertyquotation[0].property.identifier : '-' }}
                  </template>
                  <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret
                      :right="$store.state.appConfig.isRTL">
                      <template #button-content>
                        <feather-icon size="16"
                          icon="MoreVerticalIcon"
                          class="align-middle text-body"/>
                      </template>
                      <b-dropdown-item @click.prevent="_auditQuotations(data.item)" v-if="permissions_visible.audits_quotations">
                        <feather-icon icon="BookmarkIcon"/>
                        <span class="align-middle ml-50">Auditoria</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click.prevent="_showQuotations(data.item)" v-if="permissions_visible.audits_quotations">
                        <feather-icon icon="ArrowRightCircleIcon" />
                        <span class="align-middle ml-50">Detalles</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
                <b-pagination
                  align="right"
                  v-model="currentPage"
                  hide-goto-end-buttons
                  :total-rows="quotations.total"/>
              </div>
            </b-overlay>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {mapActions, mapState, mapGetters} from 'vuex'
  import ToastificationContent from "@core/components/toastification/ToastificationContent";

  export default {
    name: 'QuotationsList',
    data () {
      return {
        is_admin:false,
        permissions_visible:{
          audits_quotations:false,
          show_quotations:false,
          download_quotations:false,
          delete_quotation:false,
        },
        overlayList: false,
        overlayForm: false,
        currentPage: 1,
        filtersForm: {
          real_state_agency:"",
          project: '',
          start: '',
          end: '',
          page: 1
        },
        tableColumns: [
          {key: 'customer.email', sortable: true, label: 'Email'},
          {key: 'customer.name', sortable: true, label: 'Nombre'},
          {key: 'customer.last_name', sortable: true, label: 'Apellido'},
          {key: 'customer.phone', sortable: true, label: 'Télefono'},
          {key: 'created_at', sortable: true, label: 'Fecha'},
          {key: 'propertyquotation[0].property.project.name', sortable: true, label: 'Proyecto'},
          {key: 'propertyquotation[0].property.identifier', sortable: true, label: 'Unidad'},
          {key: 'actions', sortable: false, label: 'Acciones'},
        ],
        filteredProjects:[]
      }
    },
    mounted(){
      this.permissions_visible = this.$PermissionHelper.checkPermissions(this.permissions_visible);
      this.is_admin = this.$PermissionHelper.getRole().toLowerCase() == 'admin';
      this.filteredProjects = this.projects;
    },
    beforeMount() {
      this.filtersForm.page = this.currentPage
      this._fetchQuotations(this.filtersForm)
    },
    watch: {
      filtersForm: {
        handler(filter){
          this._fetchQuotations(filter)
        },
        deep: true, 
      },
      "filtersForm.real_state_agency": function (val) { 
        if(val == null){ 
          this.filteredProjects = this.projects;
        }else{ 
          this.filteredProjects = [];
          this.filtersForm.project = null;
          this.projects.map((valor) => {
            if(valor.realstateagency.id == val){
                this.filteredProjects.push(valor)
              }
          })
        }
      },
      currentPage (val) {
        this.filtersForm.page = this.currentPage
        this._fetchQuotations(this.filtersForm)
      }
    },
    computed: {
      ...mapState('quotations',['quotations']),
      ...mapGetters('profile',['projects','realStateAgencies']),
    },
    methods: {
      a(x){
        return 1
      },
      ...mapActions('quotations',['exportQuotations', 'fetchQuotations']),
      _auditQuotations(quotation) {
        this.$router.push({ name: 'quotations-audits', params: { id: quotation.id, audits:quotation } })
      },
      _showQuotations(quotation) {
        this.$router.push({ name: 'quotations-details', params: { id: quotation.id, quotation:quotation } })
      },
      async _fetchQuotations (filter) {
        this.overlayList = true
        await this.fetchQuotations(filter)
        .finally(() => {
          this.overlayList = false
        })
      },
      async _exportQuotations() {
        this.overlayForm = true
        await this.exportQuotations(this.filtersForm)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se ha enviado un correo con el archivo',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al exportar el archivo',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.overlayForm = false
        })
      },
      _onHidden() {
        //this.$refs.button.focus()
      },
    },
  }
</script>

