<template>
  <div id="quotations">
    <quotations-list></quotations-list>
  </div>
</template>

<script>
  import QuotationsList from './components/QuotationsList'

  export default{
    name: 'QuotationsView',
    components: {QuotationsList}
  }
</script>
